import React, { useState, useEffect, useRef } from "react";
import MovieWheelItem from "./MovieWheelItem";
import axios from "axios";

const MovieWheel = () => {
  const [movies, setMovies] = useState([]);

  const wheelRef = useRef(null); // Reference to the wheel container

  useEffect(() => {
    async function getMovies() {
      try {
        const response = await axios.get("https://api.top250.danielfew.uk/movies");
        if (response.status === 200) {
          console.log("The response is: ", response.data);
          setMovies(response.data);
        }
      } catch (error) {
        if (error.response) {
          alert("Failed to fetch movies: " + error.status);
        }
      }
    }

    getMovies();
  }, []);

  const animateWheelToMovie = (movieIndex) => {
    const wheel = wheelRef.current;
    if (!wheel) return;

    const selectedMovieCard = wheel.children[movieIndex]; // Assuming direct children are movie cards
    const moviePosition = selectedMovieCard.offsetLeft;
    const centerPosition = window.innerWidth / 2 - selectedMovieCard.offsetWidth / 2;
    const scrollTo = moviePosition - centerPosition;

    console.log("Scrolling to: ", scrollTo, " for movie: ", movieIndex);

    wheel.scrollTo({
      left: scrollTo,
      behavior: "smooth", // Smooth scroll animation
    });
  };

  const handleButtonClick = () => {
    const movie_to_pick = Math.floor(Math.random() * movies.length); // Adjust as needed
    console.log(movie_to_pick);
    animateWheelToMovie(movie_to_pick);
  };

  return (
    <>
      <div className="wheel">
        <div ref={wheelRef} className="wheel-scroll">
          {[].concat(...Array(10).fill(movies)).map((movie, index) => (
            <MovieWheelItem key={index} movie={movie} />
          ))}
        </div>
      </div>
      <div className="button-container">
        <button className="bg-gray-900" onClick={handleButtonClick} className="roller-button">
          Spin the wheel!
        </button>
      </div>
    </>
  );
};

export default MovieWheel;
