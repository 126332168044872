import { useEffect, useState } from "react";
import WatchedMoviesListItem from "./WatchedListItem";
import axios from "axios";

const WatchedMoviesList = ({ movies }) => {
  const [watchedMovies, setWatchedMovies] = useState([]);

  useEffect(() => {
    async function getWatchedMovies() {
      try {
        const response = await axios.get("https://api.top250.danielfew.uk/movies/watched");
        if (response.status === 200) {
          setWatchedMovies(response.data);
        }
      } catch (error) {
        if (error.response) {
          alert("Failed to fetch watched movies: " + error.status);
        }
      }
    }

    getWatchedMovies();
  }, []);

  return (
    <ul role="list" className="space-y-5">
      {watchedMovies.map((movie) => (
        <WatchedMoviesListItem key={movie.id} movie={movie} />
      ))}
    </ul>
  );
};

export default WatchedMoviesList;
