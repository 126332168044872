import React, { useState, useEffect } from "react";
import AuthContext from "./context/AuthContext"; // import AuthContext
import RollerPage from "./pages/RollerPage"; // import your Roller page
import LoginPage from "./pages/LoginPage"; // import your Login page

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const restoreToken = async () => {
      const token = await localStorage.getItem("jwt");
      if (token) {
        setUser(token);
      }
    };

    console.log("Attempting to restore token...");

    restoreToken();
  }, []);

  return <AuthContext.Provider value={{ user, setUser }}>{user ? <RollerPage /> : <LoginPage />}</AuthContext.Provider>;
};

export default App;
