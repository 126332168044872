import MovieRating from "../MovieRating";

export default function MovieListItem({ movie }) {
  return (
    <li key={movie.id} className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
      <div className="flex space-x-3">
        <div className="min-w-0 flex-1">
          <p className="text-sm font-semibold text-gray-900">{movie.title}</p>
          <p className="text-sm text-gray-500">{movie.watched_timestamp}</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-sm text-gray-400">{movie.description}</p>
      </div>
      <MovieRating up={movie.upVotes} down={movie.downVotes} userRating={movie.userRating} />
    </li>
  );
}
