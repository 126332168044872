const MovieWheelItem = ({ movie }) => {
  const movie_colors = ["#415CC3", "#CBA004", "#8039F5", "#AD3C3E"];

  function randomColor() {
    return movie_colors[Math.floor(Math.random() * movie_colors.length)];
  }

  return (
    <div style={{ opacity: movie.watched_timestamp ? 0.1 : 1, backgroundSize: "cover", backgroundImage: `url(${movie.imageUrl})` }} className="movie-card">
      <div style={{ backgroundColor: randomColor() }} className="movie-card__bottom">
        {movie.title}
      </div>
    </div>
  );
};

export default MovieWheelItem;
