import { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import InputField from "../fields/InputField";
import SubmitButton from "../fields/SubmitButton";
import axios from "axios";

const LoginForm = () => {
  const { setUser } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({ email_address: "", password: "" });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://api.top250.danielfew.uk/auth", loginData);

      if (response.status === 200) {
        localStorage.setItem("jwt", response.data.token);
        setUser(response.data.token);
      } else {
        alert("Login failed: " + response.data.message);
      }
    } catch (error) {
      if (error.response) {
        alert("Login failed: " + error.response.data.message);
      }
    }
  };

  return (
    <form onSubmit={handleLogin} className="space-y-6" action="#" method="POST">
      <InputField id="email_address" name="email_address" type="email" autoComplete="email" required onChange={handleChange} labelText="Email address" />
      <InputField id="password" name="password" type="password" autoComplete="current-password" required onChange={handleChange} labelText="Password" />
      <SubmitButton label="Sign in" />
    </form>
  );
};

export default LoginForm;
