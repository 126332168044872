import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/20/solid";

const MovieRating = ({ up, down, userRating }) => {
  const upColor = userRating === null ? "text-gray-400" : userRating === "up" ? "text-green-600" : "text-gray-400";
  const downColor = userRating === null ? "text-gray-400" : userRating === "down" ? "text-red-600" : "text-gray-400";

  return (
    <div className="mt-4 flex space-x-4">
      <button className="flex items-center space-x-2">
        <HandThumbUpIcon className={`h-5 w-5 ${upColor}`} />
        <span className={`text-sm ${upColor}`}>{up}</span>
      </button>
      <button className="flex items-center space-x-2">
        <HandThumbDownIcon className={`h-5 w-5 ${downColor}`} />
        <span className={`text-sm ${downColor}`}>{down}</span>
      </button>
    </div>
  );
};

export default MovieRating;
