import { useState } from "react";
import Sidebar from "../components/ui/Sidebar";
import WatchedList from "../components/ui/WatchedList";
import MovieWheel from "../components/ui/MovieWheel";

const RollerPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="roller-page">
      <div className="wrapper">
        <div className="top"></div>
        <div className="middle">
          <div className="line"></div>
          <MovieWheel />
        </div>
        <div className="bottom"></div>
      </div>
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} title="Watched movies">
        <WatchedList movies={[{ id: 1, title: "The Great Escape", description: "Imprisoned Allied soldiers plot their escape from a German camp to force Nazi soldiers away from the battle during WWII. However, they soon find that the stakes are much higher than they anticipated.", watched_timestamp: "11-02-2024", upVotes: 2, downVotes: 1, userRating: "up" }]} />
      </Sidebar>
    </div>
  );
};

export default RollerPage;
